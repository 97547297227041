enum ObjectEnum {
    UNKNOWN = "",
    PERMISSION = "permission",
    ROLE = "role",
    USER = "user",
    NODE_STATUS = "node-status",
    SCHEMA = "schema",
    FEATURE = "feature",
    NAMESPACE = "namespace",
    CLIENT_CONFIG_OVERRIDE = "client-config-override",
    DISCOVERY_POOL_CONFIG = "discovery-pool-config",
    DISCOVERY_POOL_STATUS = "discovery-pool-status",
    DISCOVERY_POOL_SERVERS = "discovery-pool-servers",
    DISCOVERY_GROUP_STATUS = "discovery-group-status",
    DISCOVERY_GROUP_SERVERS = "discovery-group-servers",
    DISCOVERY_LOCATION_STATUS = "discovery-location-status",
    DISCOVERY_LOCATION_SERVERS = "discovery-location-servers",
    PROJECT_AUTH_CONFIG = "project-auth-config",
}

const GENERAL_NAMESPACE = "";

enum ObjectTab {
    UI = "ui",
    JSON = "json",
}

enum PermissionEnum {
    GET = "get",
    PUT = "put",
    DELETE = "delete",
    LIST = "list",
    WATCH = "watch",
    ALL = "all",
}

enum ResultResponse {
    GET_RESULT_OK = "GET_RESULT_OK",
    GET_RESULT_NOT_FOUND = "GET_RESULT_NOT_FOUND",
    PUT_RESULT_OK = "PUT_RESULT_OK",
    PUT_RESULT_NOT_FOUND = "PUT_RESULT_NOT_FOUND",
    PUT_RESULT_ALREADY_EXISTS = "PUT_RESULT_ALREADY_EXISTS",
    PUT_RESULT_CONFLICT = "PUT_RESULT_CONFLICT",
    DELETE_RESULT_OK = "DELETE_RESULT_OK",
}
export { ObjectTab, ResultResponse, PermissionEnum, ObjectEnum, GENERAL_NAMESPACE };
